import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';

function WhyWeBetter() {
  return (
    <section className="why-we-better">
      <Container>
        <Row className="align-items-center">
          {/* Left Section */}
          <Col lg={6} md={12} className="mb-4">
            <div className="left-content">
              <h4>----WHY WE BETTER----</h4>
              <h1>REASONS TO CHOOSE EASYTOOLS</h1>
              <p>
                We know that finding the right team to handle your home repairs
                and maintenance needs can be overwhelming, but with Toolboys, you
                can rest assured that you’re in good hands. Here are some reasons
                why you should choose us for all your home service needs:
              </p>
              <ul className="features-list">
                <li> You approve the price before we start the job</li>
                <li> We treat your property like our own</li>
                <li> We offer options for pricing on every repair</li>
                <li> Expert, guaranteed workmanship</li>
              </ul>
            </div>
          </Col>

          {/* Right Section */}
          <Col lg={6} md={12}>
            <Row>
              {[
                {
                  imgSrc: '/images/icon02.png',
                  imgWidth: '120px',
                  title: 'Reliability',
                  text:
                    'At Toolboys, we understand that your time is valuable, which is why we always arrive on time, and complete our work in a timely and efficient manner. We take pride in our work, and always strive to exceed our clients’ expectations.',
                },
                {
                  imgSrc: '/images/icon01.png',
                  imgWidth: '70px',
                  title: 'Experienced',
                  text:
                    'Our team of skilled professionals have years of experience in their respective fields, and are trained to handle any job, big or small. From plumbing and electrical work to masonry and tiling, our experts can tackle it all with ease.',
                },
                {
                  imgSrc: '/images/icon03.png',
                  imgWidth: '70px',
                  title: 'Quality',
                  text:
                    'We use only the highest quality materials and equipment to ensure that your repairs and maintenance are done to the highest standards. Our team of experts always pays attention to detail, ensuring that every job is done right the first time.',
                },
                {
                  imgSrc: '/images/icon04.png',
                  imgWidth: '70px',
                  title: 'Affordable Pricing',
                  text:
                    'We understand that home repairs and maintenance can be costly, which is why we offer competitive pricing without sacrificing quality. We believe that everyone deserves access to top-notch home services, and we strive to make our services affordable for all.',
                },
              ].map((card, index) => (
                <Col sm={6} xs={12} className="mb-4" key={index}>
  <Card className="h-100">
    <Card.Body className="text-center">
      <div className="icon mb-3">
        <img src={card.imgSrc} style={{ width: card.imgWidth }} alt={card.title} />
      </div>
      <Card.Title style={{ fontSize: '18px', fontWeight: 'bold' }}>{card.title}</Card.Title> {/* Smaller title font size */}
      <Card.Text style={{ fontSize: '12px', color: '#555' }}>{card.text}</Card.Text> {/* Smaller text font size */}
    </Card.Body>
  </Card>
</Col>

              ))}
            </Row>
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default WhyWeBetter;
