import React, { useState } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css"; // Toastify CSS

const AppointmentForm = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    date: "",
    service: "",
    message: "",
  });

  const adminPhoneNumber = "+971521708987";

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const whatsappMessage = `
Hello! I would like to make an appointment. Here are my details:
- Name: ${formData.name}
- Email: ${formData.email}
- Phone: ${formData.phone}
- Date: ${formData.date}
- Service: ${formData.service}
- Message: ${formData.message}
    `;

    const encodedMessage = encodeURIComponent(whatsappMessage);
    const whatsappUrl = `https://wa.me/${adminPhoneNumber}?text=${encodedMessage}`;
    window.open(whatsappUrl, "_blank");

    // Toast notification
    toast.success("Your appointment request has been sent to the EasyTools!", {
      position: "top-center",
    });
  };

  return (
    <div style={{ backgroundColor: "#1e3b57", color: "white", marginBottom:"190px" }} id="appointments">
      <Container className="mb-5" >
        <Row className="align-items-center position-relative">
          {/* Left Section */}
          <Col lg={6} className="mb-4 mb-lg-0">
            <h2 style={{ color: "orange", fontSize: "24px" }}>----Make an Appointment----</h2>
            <h3 className="fw-bold">
              Emergency Plumbing Service 24 Hours <br /> 7 Days A Week
            </h3>
            <p style={{ color: "grey" }}>


              Facing a plumbing emergency? We've got you covered 24/7 Our team of skilled
              professionals is ready to tackle leaks, clogs, and water heater issues any time of
              the day or night.
            </p>
          </Col>

          {/* Right Section */}
          <Col lg={6}>
            <div
              style={{
                backgroundColor: "white",
                padding: "1rem",
                borderRadius: "10px",
                boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
                position: "relative",
                bottom: "-120px", // Pull the form slightly outside the container
              }}
              className="responsive-form"
            >
              <h4 className="mb-3">Make an Appointment</h4>
              <Form onSubmit={handleSubmit}>
                <Row>
                  <Col md={6} className="mb-3">
                    <Form.Control
                      type="text"
                      name="name"
                      value={formData.name}
                      onChange={handleInputChange}
                      required
                      placeholder="Name"
                    />
                  </Col>
                  <Col md={6} className="mb-3">
                    <Form.Control
                      type="email"
                      name="email"
                      value={formData.email}
                      onChange={handleInputChange}
                      required
                      placeholder="Email"
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={6} className="mb-3">
                    <Form.Control
                      type="tel"
                      name="phone"
                      value={formData.phone}
                      onChange={handleInputChange}
                      required
                      placeholder="Phone"
                    />
                  </Col>
                  <Col md={6} className="mb-3">
                    <Form.Control
                      type="date"
                      name="date"
                      value={formData.date}
                      onChange={handleInputChange}
                      required
                    />
                  </Col>
                </Row>
                <Form.Group className="mb-3">
                  <Form.Select
                    name="service"
                    value={formData.service}
                    onChange={handleInputChange}
                    required
                  >
                    <option value="">Select Service</option>
                    <option value="Plumbing">Plumbing</option>
                    <option value="Water Heater Installation">Water Heater Installation</option>
                    <option value="Pipe Repair">Pipe Repair</option>
                    <option value="Faucet Repair">Faucet Repair</option>
                  </Form.Select>
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Control
                    as="textarea"
                    name="message"
                    value={formData.message}
                    onChange={handleInputChange}
                    placeholder="Message"
                    rows={3}
                  />
                </Form.Group>
                <Button
                  type="submit"
                  style={{
                    backgroundColor: "orange",
                    border: "none",
                    width: "100%",
                    marginBottom: "20px",
                  }}
                  className="btn-orange"
                >
                  Make Appointment
                </Button>
              </Form>
            </div>
          </Col>
        </Row>
      </Container>

      <ToastContainer />
    </div>
  );
};

export default AppointmentForm;
