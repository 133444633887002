import React from "react";
import { FaWhatsapp } from "react-icons/fa";

const WhatsAppSticky = () => {
  return (
    <div style={styles.container}>
      <div style={styles.messageBox}>
        <p style={styles.message}>
          Need any help?{" "}
          <a href="#contact" style={styles.contactLink}>
            Contact Us
          </a>
        </p>
        <button style={styles.closeButton} onClick={() => hideMessage()}>
          &times;
        </button>
      </div>
      <a
        href="https://wa.me/+971521708987" // Replace with your WhatsApp number
        target="_blank"
        rel="noopener noreferrer"
        style={styles.iconContainer}
      >
        <FaWhatsapp style={styles.icon} />
      </a>
    </div>
  );
};

// Function to hide the message
const hideMessage = () => {
  const messageBox = document.querySelector("#messageBox");
  if (messageBox) {
    messageBox.style.display = "none";
  }
};

const styles = {
  container: {
    position: "fixed",
    bottom: "20px",
    right: "20px",
    zIndex: 1000,
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
  },
  messageBox: {
    backgroundColor: "#333",
    color: "#fff",
    padding: "10px 15px",
    borderRadius: "8px",
    marginBottom: "10px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    maxWidth: "250px",
  },
  message: {
    margin: 0,
    fontSize: "14px",
  },
  contactLink: {
    color: "#00ff99",
    textDecoration: "none",
    fontWeight: "bold",
  },
  closeButton: {
    background: "none",
    border: "none",
    color: "#fff",
    fontSize: "18px",
    cursor: "pointer",
    marginLeft: "10px",
  },
  iconContainer: {
    backgroundColor: "#25D366",
    borderRadius: "50%",
    padding: "10px",
    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.2)",
  },
  icon: {
    color: "#fff",
    fontSize: "32px",
  },
};

export default WhatsAppSticky;
