import React from "react";
import { Container, Row, Col, Card, Button } from "react-bootstrap";
import "../App.css";

function Contact() {
  return (
    <div>
      {/* Hero Section */}
      <section id="contact-section" className="hero-section">
        {/* Overlay */}
        <div className="overlay"></div>

        {/* Hero Content */}
        <div className="hero-content">
          <h2>Need Help?</h2>
          
          <h1>Don't Hesitate to Contact Us for Assistance</h1>
          <p>
            At EasyTool Technical Services, we understand the importance of
            prompt and reliable assistance. Whether you have plumbing issues,
            need a new water heater installed, or require emergency services,
            our team is always ready to help. Reach out to us, and we'll ensure
            your home or business gets the attention it deserves. We're here to
            solve your plumbing problems with professionalism and care!
          </p>
        </div>
      </section>

      {/* Services Section */}
      <section className="services-section py-5">
  <Container className="custom-container">
    <Row>
      {/* Sales Support Card */}
      <Col lg={4} md={6} sm={12} className="mb-4">
        <Card className="text-center">
          <Card.Img
            variant="top"
            src="/images/cont01.PNG"
            alt="Home Service"
            className="img-fluid cards-img"
          />
          <Card.Body>
            <Card.Title>Sales Support</Card.Title>
            <Card.Text>Need help with sales? <br/>Contact our team now!</Card.Text>
            <Button
              href="https://wa.me/971521708987"
              target="_blank"
              rel="noopener noreferrer"
              variant="primary"
              className="w-100"
            >
              Chat Us
            </Button>
          </Card.Body>
        </Card>
      </Col>

      {/* Ticket Support Card */}
      <Col lg={4} md={6} sm={12} className="mb-4">
        <Card className="text-center">
          <Card.Img
            variant="top"
            src="/images/cont02.PNG"
            alt="Commercial Service"
            className="img-fluid cards-img"
          />
          <Card.Body>
            <Card.Title>Ticket Support</Card.Title>
            <Card.Text>
              Have an issue? Raise a ticket and get prompt support from our
              experts.
            </Card.Text>
            <Button
              href="mailto:etools754@gmail.com"
              target="_blank"
              rel="noopener noreferrer"
              variant="primary"
              className="w-100"
            >
              Send Email
            </Button>
          </Card.Body>
        </Card>
      </Col>

      {/* Help Center Card */}
      <Col lg={4} md={6} sm={12} className="mb-4">
        <Card className="text-center">
          <Card.Img
            variant="top"
            src="/images/cont03.PNG"
            alt="Enterprise Service"
            className="img-fluid cards-img"
          />
          <Card.Body>
            <Card.Title>Help Center</Card.Title>
            <Card.Text>
              Explore our Help Center or get in touch for detailed assistance.
            </Card.Text>
            <Button
              href="https://wa.me/971521708987"
              target="_blank"
              rel="noopener noreferrer"
              variant="primary"
              className="w-100"
            >
              Contact
            </Button>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  </Container>
</section>

    </div>
  );
}

export default Contact;
