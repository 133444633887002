import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import CountUp from "react-countup";
import { useInView } from "react-intersection-observer";

const KangpipaSection = () => {
  const { ref, inView } = useInView({ triggerOnce: true });

  return (
    <section className="kangpipa-section py-5">
      <Container>
        {/* Section Title */}
        <h2 className="section-title text-center mb-3">Discover EasyTool</h2>

        {/* Section Heading */}
        <h2 className="section-heading text-center mb-4">
          We Take Care Of All Your <br /> Plumbing & Water Heater Needs
        </h2>

        {/* Section Description */}
        <p className="section-description text-center mb-5">
          At EasyTool Technical Service, we specialize in providing top-notch
          solutions for water heater installation, repair, and maintenance, as well as comprehensive plumbing services. With a commitment to quality, we ensure your home or business runs smoothly and efficiently.
        </p>

        {/* Experience Section */}
        <div ref={ref} className="experience-container text-center">
  <Container>
    <Row className="justify-content-center">
      <Col md={3} sm={6} className="text-center mb-4 counter-box">
        {inView && (
          <span className="CountUp display-4">
            <CountUp end={150} duration={2} suffix="+" />
          </span>
        )}
        <p className="counter-text mt-2">Certified Experts</p>
      </Col>
      <Col md={3} sm={6} className="text-center mb-4 counter-box">
        {inView && (
          <span className="CountUp display-4">
            <CountUp end={13000} duration={2} separator="," />
          </span>
        )}
        <p className="counter-text mt-2">Projects Completed</p>
      </Col>
      <Col md={3} sm={6} className="text-center mb-4 counter-box">
        {inView && (
          <span className="CountUp display-4">
            <CountUp end={27000} duration={2} separator="," />
          </span>
        )}
        <p className="counter-text mt-2">Happy Customers</p>
      </Col>
      <Col md={3} sm={6} className="text-center mb-4 counter-box">
        {inView && (
          <span className="CountUp display-4">
            <CountUp end={25} duration={2} suffix="+" />
          </span>
        )}
        <p className="counter-text mt-2">Years of Experience</p>
      </Col>
    </Row>
  </Container>
</div>


      </Container>
    </section>
  );
};

export default KangpipaSection;
