import React from 'react';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import { FaWrench, FaCog, FaPlusCircle } from 'react-icons/fa'; // Correct icon import
import AboutUs from './AboutUs';
import Expertise from './Expertise';
import WhyWeBetter from './WhyWeBetter';
import Testimonial from './Testimonials';
import Projects from './Projects';
import KangpipaSection from './KangpipaSection';
import Services from './Services';
import Contact from './Contact';
import AppointmentForm from './AppointmentForm';
import { scroller } from "react-scroll";

function HomePage() {
  const scrollToConact = () => {
    scroller.scrollTo("contact-section", {
      smooth: true,
      duration: 500,
    });
  };
  return (
    <div>
      {/* Slider Section */}
      <section
        className="slider"
        style={{
          backgroundImage: 'url("/images/crousel-01.jpeg")',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          padding: '180px 0',
          position: 'relative',
        }}
      >
        {/* Black Overlay */}
        <div
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            zIndex: 1,
          }}
        ></div>

        <div className="container" style={{ position: 'relative', zIndex: 2 }}>
          <div className="row">
            <div className="col-lg-7 text-white">
              <div className="hero-text">
                <h1>
                  Reliable  <span style={{ color: 'orange' }}> Plumbing</span>  Solutions for Your{' '}
                  <span style={{ color: 'orange' }}>Comfort!</span>
                </h1>
                <p>
                  Ensure your home's plumbing and water heating systems are
                  operating at peak efficiency. For optimal energy savings, keep
                  your water heater's temperature set to 120°F. This not only
                  reduces power consumption but also helps prolong the life of
                  your equipment.
                </p>
                <div className="button">
                <button
                        href="#"
                        id="appointment"
                        onClick={scrollToConact}
                        className="btn btn-secondary" style={{ backgroundColor: 'orange',color:'white', borderColor: '#1f3b55',marginRight:'5px' }}
                      >
                        Contact Us
                      </button>
                  <a href="#" className="btn " style={{ backgroundColor: '#1f3b55',color:'white', borderColor: '#1f3b55' }}>
                    Learn More
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* End Slider Section */}

      {/* Services Section */}
      <section className="content-area services-section">
      <Container>
        <Row className="mt-5">
          {/* Repair Card */}
          <Col lg={4} md={6} sm={12}>
            <Card className="service-card service-card-blue my-2">
              <Card.Body>
                <FaWrench size={50} color="#fff" />
                <Card.Title className="text-white">Plumbing Repairs</Card.Title>
                <Card.Text className="text-white">
                  From minor leaks to major repairs, our experts will ensure
                  your plumbing system is back in top shape.
                </Card.Text>
               
              </Card.Body>
            </Card>
          </Col>

          {/* Maintenance Card */}
          <Col lg={4} md={6} sm={12}>
            <Card className="service-card service-card-orange my-2">
              <Card.Body>
                <FaCog size={50} color="#fff" />
                <Card.Title className="text-white">Maintenance Services</Card.Title>
                <Card.Text className="text-white">
                  Regular maintenance is key to keeping your plumbing and water heating systems running smoothly.
                </Card.Text>
               
              </Card.Body>
            </Card>
          </Col>

          {/* Installation Card */}
          <Col lg={4} md={6} sm={12}>
            <Card className="service-card service-card-blue my-2">
              <Card.Body>
                <FaPlusCircle size={50} color="#fff" />
                <Card.Title className="text-white">Installation Services</Card.Title>
                <Card.Text className="text-white">
                  Whether it's a new water heater or a plumbing upgrade, we provide expert installation services.
                </Card.Text>
               
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </section>
      {/* End Services Section */}
      <AboutUs/>
      <Expertise/>
      <WhyWeBetter/>
      <Testimonial/>
      <Projects/>
      <KangpipaSection/>
      <Services/>
      <Contact/>
      <AppointmentForm/>
    </div>
  );
}

export default HomePage;
