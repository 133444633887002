import React from "react";
import "../App.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faScrewdriverWrench } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-scroll";
import { Navbar, Nav, Container } from "react-bootstrap";

const CustomNavbar = () => {
  return (
    <Navbar expand="lg" bg="white" className="custom-navbar">
      <Container>
        <Navbar.Brand href="#" className="d-flex align-items-center">
          <FontAwesomeIcon
            icon={faScrewdriverWrench}
            className="brand-icon me-2"
          />
          <div className="brand-text">
            <span className="brand-title">Easytools</span>
            <small className="brand-subtitle">Technical Services</small>
          </div>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="navbarNav" />
        <Navbar.Collapse id="navbarNav">
          <Nav className="ms-auto">
            <Nav.Item>
              <Link to="home" smooth={true} duration={500} className="nav-link">
                Home
              </Link>
            </Nav.Item>
            <Nav.Item>
              <Link
                to="about"
                smooth={true}
                duration={500}
                className="nav-link"
              >
                About Us
              </Link>
            </Nav.Item>
            <Nav.Item>
              <Link
                to="services"
                smooth={true}
                duration={500}
                className="nav-link"
              >
                Services
              </Link>
            </Nav.Item>
            <Nav.Item>
            <Link to="appointments" smooth={true} duration={500} className="btn btn-appointment">
    Appointment
  </Link>
            </Nav.Item>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default CustomNavbar;
