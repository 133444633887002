import React from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone } from "@fortawesome/free-solid-svg-icons";
function AboutUs() {
  return (
    <div>
         <div className="container-xxl py-5">
        <div className="container" id="about">
          <div className="row g-5">
            <div className="col-lg-6">
              <h6
                className="text-uppercase about"
                style={{ color: "orange", fontWeight: "bold" }}
              >
                -----About Us-----
              </h6>

              <h1 className="mb-4" style={{ color: "#1f3b55" }}>
                Welcome to our Water Heater service
              </h1>
              <p className="mb-4">
                We understand that having a functioning water heater is
                essential to your daily routine, which is why we offer emergency
                services to quickly address any problems you may be facing. Our
                skilled professionals have years of experience working with a
                variety of water heater makes and models and can quickly
                identify the source of the problem.
                <br />
                <br />
                We offer a range of water heater services, including
                installation, repair, and maintenance. Whether you need a new
                unit installed, a simple repair, or routine maintenance, we have
                you covered. We can also help with issues such as leaky tanks,
                corroded pipes, and malfunctioning thermostats
              </p>
              <p className="fw-medium " style={{ color: "#1f3b55" }}>
                ✔ Residential &amp; commercial plumbing
              </p>
              <p className="fw-medium " style={{ color: "#1f3b55" }}>
                ✔ Quality services at affordable prices
              </p>
              <p className="fw-medium " style={{ color: "#1f3b55" }}>
                ✔ Immediate 24/ 7 emergency services
              </p>
              <div
                className=" d-flex align-items-center p-4 mt-5 emergency"
                style={{ backgroundColor: "#1f3b55" }}
              >
                <div
                  className="d-flex flex-shrink-0 align-items-center justify-content-center"
                  style={{
                    width: "60px",
                    height: "60px",
                    color: "orange",
                    fontSize: "30px",
                    border: "2px solid orange",
                    borderRadius: "10px",
                    padding: "10px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <FontAwesomeIcon icon={faPhone} />
                </div>

                <div className="ms-3">
                  <p className="fs-5 fw-medium mb-2 text-white">
                    Emergency 24/7
                  </p>
                  <h3 className="m-0" style={{ color: "orange" }}>
                    +971521708987
                  </h3>
                </div>
              </div>
            </div>
            <div className="col-lg-6 pt-4" style={{ minHeight: "500px" }}>
              <div
                className="position-relative h-100 wow fadeInUp"
                data-wow-delay="0.5s"
              >
                <img
                  className="position-absolute img-fluid w-100 h-100"
                  src="/images/about-01.jpg"
                  style={{ objectFit: "cover", padding: "0 0 50px 100px" }}
                  alt=""
                />
                <img
                  className="position-absolute start-0 bottom-0 img-fluid bg-white pt-2 pe-2 w-50 h-50"
                  src="/images/about-02.jpg"
                  style={{ objectFit: "cover" }}
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* About End */}
    </div>
  )
}

export default AboutUs