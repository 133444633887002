import React from "react";
import { Container, Row, Col, Card, Button } from "react-bootstrap";
// Import the CSS file for custom styles
import { useInView } from "react-intersection-observer";
import { scroller } from "react-scroll";

function Expertise() {
  const scrollToServices = () => {
    scroller.scrollTo("services", {
      smooth: true,
      duration: 500,
    });
  };
 
  return (
    <div>
      {/* Hero Section */}
      <section className="hero-section">
        <div className="overlay"></div>
        <div className="hero-content">
          <h2>----OUR EXPERTISE----</h2>
          <h1>Dedicated To Excellence And Service.</h1>
          <p>
          Our technicians are fully licensed, insured, and bonded, and we only
            use high-quality products and parts to ensure that your water heater
            is functioning properly and efficiently. We take pride in our work
            and strive to provide exceptional customer service, so you can feel
            confident that you are in good hands. If you need water heater
            repair or installation services, give us a call today. We offer 24/7
            emergency services to ensure that your water heater is up and
            running as quickly as possible.
          </p>
        </div>
      </section>

      {/* Services Section */}
      <section className="expertise-section">
        <Container>
          <Row className="justify-content-center">
            <Col lg={4} md={6} sm={12} className="mb-4">
              <Card className="equal-card">
                <Card.Img
                  variant="top"
                  src="/images/exp01.jpg"
                  className="services-img"
                />
                <Card.Body >
                  <Card.Title  >Home Service</Card.Title>
                  <Card.Text>
                    Providing reliable plumbing and water heater solutions for
                    your home, ensuring comfort and efficiency in every corner.
                  </Card.Text>
                  <Button
           
            className="card-button"
            onClick={scrollToServices}
          >
            
            Explore Our Services →
          </Button>
                </Card.Body>
              </Card>
            </Col>

            <Col lg={4} md={6} sm={12} className="mb-4">
              <Card className="equal-card">
                <Card.Img
                  variant="top"
                  src="/images/exp02.jpg"
                  className="services-img"
                />
                <Card.Body>
                  <Card.Title>Commercial Service</Card.Title>
                  <Card.Text>
                    Expert commercial plumbing and water heating systems
                    designed to meet the demands of your business operations.
                  </Card.Text>
                  <Button className="btn-primary" onClick={scrollToServices}>Explore Our Services →</Button>
                </Card.Body>
              </Card>
            </Col>

            <Col lg={4} md={6} sm={12} className="mb-4">
              <Card className="equal-card">
                <Card.Img
                  variant="top"
                  src="/images/exp03.jpg"
                  className="services-img"
                />
                <Card.Body>
                  <Card.Title>Enterprise Service</Card.Title>
                  <Card.Text>
                    Comprehensive enterprise-level plumbing and water heater
                    solutions tailored for large-scale facilities and
                    industries.
                  </Card.Text>
                  <Button className="btn-primary " onClick={scrollToServices}>Explore Our Services →</Button>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </section>
    </div>
  );
}

export default Expertise;
