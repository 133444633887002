import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEnvelope,
  faLocationDot,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";
const Footer = () => {
  return (
    <footer
      style={{ backgroundColor: "#0b2c4a", color: "white", padding: "40px 0" }}
    >
      <div className="container">
        <div className="row">
          {/* Logo and Description */}
          <div className="col-md-3">
            <h3 style={{ color: "orange" }}>EASYTOOLS</h3>
            <p>
              When it comes to managing repair & maintenance for your business
              or home. You need an expert. Let us show you what responsive,
              reliable and accountable Support looks like in the world.
            </p>
          </div>

          {/* Navigation Links */}
          <div className="col-md-3">
            <h5>Navigation</h5>
            <ul className="list-unstyled">
              <li>
                <a href="#" style={{ color: "#ccc", textDecoration: "none" }}>
                  About Us
                </a>
              </li>
              <li>
                <a href="#" style={{ color: "#ccc", textDecoration: "none" }}>
                  Services
                </a>
              </li>
              <li>
                <a href="#" style={{ color: "#ccc", textDecoration: "none" }}>
                  Pricing
                </a>
              </li>
              <li>
                <a href="#" style={{ color: "#ccc", textDecoration: "none" }}>
                  Our Team
                </a>
              </li>
              <li>
                <a href="#" style={{ color: "#ccc", textDecoration: "none" }}>
                  Contact Us
                </a>
              </li>
            </ul>
          </div>

          {/* Useful Links */}
          <div className="col-md-3">
            <h5>Useful Links</h5>
            <ul className="list-unstyled">
              <li>
                <a href="#" style={{ color: "#ccc", textDecoration: "none" }}>
                  {" "}
                  Privacy Policy
                </a>
              </li>
              <li>
                <a href="#" style={{ color: "#ccc", textDecoration: "none" }}>
                  Terms And Conditions
                </a>
              </li>
              <li>
                <a href="#" style={{ color: "#ccc", textDecoration: "none" }}>
                  Disclaimer
                </a>
              </li>
              <li>
                <a href="#" style={{ color: "#ccc", textDecoration: "none" }}>
                  Support
                </a>
              </li>
              <li>
                <a href="#" style={{ color: "#ccc", textDecoration: "none" }}>
                  FAQ
                </a>
              </li>
            </ul>
          </div>

          {/* Contact Info */}
          <div className="col-md-3">
            <h5>Get in Touch</h5>
            <ul className="list-unstyled">
              <li style={{ color: "#ccc", marginBottom: "10px" }}>
                <FontAwesomeIcon
                  icon={faEnvelope}
                  style={{ color: "orange", marginRight: "10px" }}
                />
                etools754@gmail.com
              </li>
              <li style={{ color: "#ccc" }}>
                <FontAwesomeIcon
                  icon={faPhone}
                  style={{ color: "orange", marginRight: "10px" }}
                />
                +971521708987
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
