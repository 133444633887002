import React from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import "../App.css";

const Services = () => {
  const services = [
    {
      id: 1,
      title: "Plumber Services",
      description: "Professional plumbing services for your home and business.",
      image: "/images/servi01.PNG",
    },
    {
      id: 2,
      title: "Services & Repairs",
      description:
        "Expert services and repairs to keep everything running smoothly.",
      image: "/images/servi02.PNG",
    },
    {
      id: 3,
      title: "Sewer Repair",
      description: "Comprehensive sewer repair solutions.",
      image: "/images/servi03.PNG",
    },
    {
      id: 6,
      title: "Installation & Replace",
      description: "Installation and replacement for all fixtures.",
      image: "/images/servi04.PNG",
    },
    {
      id: 7,
      title: "Faucet & Leak Repair",
      description: "Fix leaks and install faucets with precision.",
      image: "/images/servi05.PNG",
    },
    {
      id: 8,
      title: "Water Heaters",
      description: "Water heater installation and maintenance.",
      image: "/images/servi06.PNG",
    },
  ];

  return (
    <Container className="p-5">
      {/* Header Section */}
      <Row className="align-items-center mb-5" id="services">
        <Col md={6}>
          <h6 className="text-uppercase text-orange fw-bold" style={{ fontSize: "19px",color:"orange" }}>
            --- Our Services ---
          </h6>
          <h2 className="fw-bold" style={{color:"#1f3b55" }}>
            Expert Plumbing, Reliable Water Heater Solutions
          </h2>
        </Col>
        <Col md={6}>
          <p className="text-muted">
            From routine plumbing repairs to advanced water heater
            installations, we provide comprehensive services to ensure your home
            or business runs smoothly. Our team is committed to excellence,
            delivering prompt and professional solutions tailored to your needs.
          </p>
        </Col>
      </Row>

      {/* Services Grid */}
      <Row className="gy-4">
        {services.map((service) => (
          <Col lg={4} md={6} key={service.id} className="d-flex align-items-stretch">
            <Card className="text-center p-3 border-0 shadow h-100">
              <Card.Img
                variant="top"
                src={service.image}
                style={{
                  width: "50px",
                  height: "50px",
                  objectFit: "contain",
                  margin: "0 auto",
                }}
              />
              <Card.Body>
                <Card.Title className="fw-bold">{service.title}</Card.Title>
                <Card.Text className="text-muted">
                  {service.description}
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
    </Container>
  );
};

export default Services;
