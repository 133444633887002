import React from "react";
import "../App.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEnvelope,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";
import {
  faFacebookF,
  faYoutube,
  faInstagram,
  faLinkedinIn,
} from "@fortawesome/free-brands-svg-icons";

const TopBar = () => {
  return (
    <div className="top-bar d-none d-lg-flex justify-content-between align-items-center px-3 py-3">
      {/* Contact Info */}
      <div className="contact-info d-flex align-items-center">
        <span>
          <FontAwesomeIcon icon={faEnvelope} className="icon" />{" "}
          etools754@gmail.com
        </span>
        <span>
          <FontAwesomeIcon icon={faPhone} className="icon" /> +971521708987
        </span>
      </div>

      {/* Social Icons */}
      <div className="social-icons d-flex align-items-center">
        <span>Follow Us</span>
        <FontAwesomeIcon icon={faFacebookF} className="icon" />
        <FontAwesomeIcon icon={faYoutube} className="icon" />
        <FontAwesomeIcon icon={faInstagram} className="icon" />
        <FontAwesomeIcon icon={faLinkedinIn} className="icon" />
      </div>
    </div>
  );
};

export default TopBar;
