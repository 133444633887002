import React from 'react'
import TopBar from './components/TopBar'
import CustomNavbar from './components/CustomNavbar'
import HomePage from './components/HomePage'
import Footer from './components/Footer'
import WhatsAppSticky from './components/WhatsAppSticky'

function App() {
  return (
    <div>
      <TopBar/>
      <CustomNavbar/>
      <HomePage/>
      <WhatsAppSticky/>
      <Footer/>
     
    </div>
  )
}

export default App