import { Carousel, Row, Col } from 'react-bootstrap';

const testimonials = [
  {
    quote:
      "Their service is impeccable! From fixing plumbing issues to installing my new water heater, they handled everything with professionalism and care. Highly recommend!",
    name: "Mark William",
    position: "Business Manager",
    image: "/images/test01.PNG",
  },
  {
    quote:
      "Exceptional customer service! The team was prompt, efficient, and fixed all my plumbing issues in no time. They went above and beyond my expectations.",
    name: "Sophia Johnson",
    position: "Homeowner",
    image: "/images/test02.PNG",
  },
  {
    quote:
      "I appreciate their attention to detail and expertise. They made the entire process of installing a new water heater stress-free and seamless.",
    name: "James Smith",
    position: "Entrepreneur",
    image: "/images/test03.PNG",
  },
];

const settings = {
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 3000,
};

function Testimonial() {
  return (
    <div className="testimonial-section">
      <Row className="align-items-center">
        {/* Left Image Section */}
        <Col md={6} className="image-section">
          <img
            src="https://mhkit.rometheme.pro/kangpipa/wp-content/uploads/sites/9/2022/07/male-plumber-and-female-customer-in-the-kitchen-2021-08-28-02-49-27-EKTSSW7-a.jpg"
            alt="Client Interaction"
            className="testimonial-image"
          />
        </Col>

        {/* Right Content Section */}
        <Col md={6} className="content-section">
          <p className="testimonial-heading">----Testimonial----</p>
          <h1 className="testimonial-title">What Our Clients Say</h1>
          <p className="testimonial-description">
            We take pride in the relationships we build with our clients. Here’s
            what they have to say about our exceptional plumbing and water heater
            service.
          </p>

          {/* Carousel Section */}
          <Carousel {...settings}>
            {testimonials.map((testimonial, index) => (
              <Carousel.Item key={index}>
                <blockquote className="testimonial-quote">
                  "{testimonial.quote}"
                </blockquote>
                <div className="testimonial-info">
                  <img
                    src={testimonial.image}
                    alt={testimonial.name}
                    className="testimonial-image-circle"
                  />
                  <div>
                    <h4 className="testimonial-name">{testimonial.name}</h4>
                    <p className="testimonial-position">{testimonial.position}</p>
                  </div>
                </div>
              </Carousel.Item>
            ))}
          </Carousel>
        </Col>
      </Row>
    </div>
  );
}

export default Testimonial;
