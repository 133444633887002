import React from "react";
import { Row, Col, Card } from "react-bootstrap";


const Projects = () => {
  const projectData = [
    {
      image: "/images/proj-01.jpg",
      title: "Water Heater Installation",
    },
    {
      image: "/images/proj-02.jpg",
      title: "Pipe Repair Services",
    },
    {
      image: "/images/proj-03.jpg",
      title: "Under Sink Maintenance",
    },
    {
      image: "/images/proj-04.jpg",
      title: "Kitchen Plumbing Solutions",
    },
  ];

  return (
    <div className="projects-container">
      <h2 className="projects-title">----Our Projects----</h2><br/>
      <h1 className="projects-header">
        <br/>   Some Projects We Have Done</h1>
<p className="projects-description text-center">
  Explore our diverse portfolio of plumbing projects. From efficient water heater installations to seamless kitchen plumbing solutions, our skilled professionals deliver quality and reliability in every task. Check out our work and see the difference we can make!
</p>


      <Row className="gy-4">
        {projectData.map((project, index) => (
          <Col lg={3} md={6} sm={12} key={index}>
            <Card className="project-card">
              <div className="project-image">
                <Card.Img src={project.image} alt={project.title} />
                <div className="project-hover">
                  <p>{project.title}</p>
                </div>
              </div>
            </Card>
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default Projects;
